<template>
  <div class="operation">
    <div class="condition">
      <el-date-picker class="timer condit" v-model="timePeriod" type="daterange" range-separator="-"
        value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期" @change="init">
      </el-date-picker>
    </div>
    <div class="chart">
      <div ref="chartsBox" class="charts_box"></div>
      <div class="legend_box">
        <div class="legend_li" v-for="item in PIE_SETTING" :key="item.key">
          <span class="icon" :style="{ background: item.color }"></span>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { evaluation } from '@/api/orderwall.js';
import { PIE_SETTING } from '../benchSetting.js';
import * as echarts from 'echarts';
const today = +new Date();
const beginDate = +new Date() - 1000 * 60 * 60 * 24 * 30;

const PIE_SETTING_OBJ = {};
PIE_SETTING.forEach(i => {
  PIE_SETTING_OBJ[i.key] = { ...i };
});

export default {
  data () {
    return {
      PIE_SETTING,
      PIE_SETTING_OBJ,
      operational: {},
      timePeriod: [beginDate, today],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.getOperational().then(() => {
        this.initCharts();
      });
    },
    getOperational () {
      if (!this.timePeriod || !this.timePeriod.length) this.timePeriod = [beginDate, today];
      return evaluation({ timePeriod: this.timePeriod }).then(({ body }) => {
        this.operational = body || {};
      });
    },
    initCharts () {
      let myChart = echarts.init(this.$refs.chartsBox);
      const data = [];
      const operationalKeys = Object.keys(this.operational);
      operationalKeys.forEach(i => {
        data.push({
          value: this.operational[i] || 0,
          name: this.PIE_SETTING_OBJ[i].name,
          itemStyle: {
            color: this.PIE_SETTING_OBJ[i].color,
            fontSize: '14px',
          },
        });
      });

      myChart.setOption({
        grid: {
          top: '20%',
          left: '3%',
          right: '3%',
          bottom: '12%',
        },
        tooltip: {
          textStyle: {
            fontSize: '14px',
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['45%', '85%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                formatter: function () {
                  return '评价';
                },
              },
            },
            labelLine: {
              show: false,
            },
            data,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.operation {
  padding-top: 20px;

  .condition {
    padding-right: 12px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .condit {
      font-size: 14px;
      font-weight: 400;
      color: rgba(15, 137, 255, 0.7);
      line-height: 14px;

      &.timer {
        margin-right: 12px;
      }

    }

    /deep/ .el-input__inner {
      border: none;
    }

    /deep/ .el-date-editor--daterange.el-input__inner {
      width: 250px;
    }

    /deep/ .el-range-input {
      color: rgba(15, 137, 255, 0.7);
    }

    /deep/ .el-range__icon {
      display: none;
    }
  }

  .chart {

    .charts_box {
      flex-shrink: 0;
      height: 164px;
      width: 164px;
      margin: auto;
    }

    .legend_box {
      display: flex;
      flex: 1;
      flex-flow: row wrap;
      margin: 24px 80px 0 80px;

      .legend_li {
        flex: 0 0 33%;
        padding-left: 20px;
        box-sizing: border-box;
        margin-bottom: 10px;

        .icon {
          display: inline-block;
          vertical-align: middle;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;

        }

        .name {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 400;
          color: rgba(36, 40, 50, 0.7);
          line-height: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 1560px) {
    .chart {
      display: flex;

      .charts_box {
        margin: 0;
      }

      .legend_box {
        display: flex;
        flex: 1;
        flex-flow: row wrap;
        margin: 30px 10px 30px 0;

        .legend_li {
          flex: 0 0 49%;
          padding-left: 0;
        }
      }
    }

  }
}
</style>
